import React from "react"
import { Helmet } from "react-helmet"
import useSiteMetadata from "./hooks/useSiteMetadata"

import splytIcon from '../assets/splyt-icon.png'

const SiteMetadata = ({ pathname }) => {
  const { siteUrl, title, description, twitter } = useSiteMetadata()

  const href = `${siteUrl}${pathname}`

  const locale = 'en';

  return (
    <Helmet defer={false} defaultTitle="Splyt - Share Money & Pay Together" titleTemplate={`%s | ${title}`}>
      <html lang={locale} />
      <link rel="canonical" href={href} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1"
      />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${siteUrl}${splytIcon}`} />
      <meta property="og:url" content={href} />

      <meta name="twitter:site" content={twitter} />
      <meta name="twitter:image" content={`${siteUrl}${splytIcon}`} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  )
}

export default SiteMetadata
