import { css } from 'styled-components';

export const background = {
  bg: '#F5F8FC',
  bg2: '#F8F8FA',
};

export const color = {
  // Palette
  primary: '#3986ED',

  textPrimary: '#151F32',
  textSecondary: '#737B89',

  cg700: '#9198A7',
  cg800: '#5B6372',
  cg900: '#151F32',

  stroke: '#D3D9E1',
};

export const spacing = {
  padding: {
    small: 10,
    medium: 20,
    large: 30,
  },
  borderRadius: {
    small: 5,
    default: 8,
  },
};

export const typography = {
  type: {
    primary: 'Inter, "Helvetica Neue", Helvetica, Arial, sans-serif',
  },
  weight: {
    regular: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
  },
  size: {
    h2: 32,
    h3: 22,
    h4: 18,
    h4mobile: 17,
    p1: 18,
    p2: 17,
  },
};

export const layout = {
  width: {
    maxWidth: '1080px'
  },
  maxWidth: 1080,
  sectionPaddingTop: '100px',
  sectionPaddingBottom: '100px',
  gutterWidth: 16,
  viewportWidthSmall: 375,
  viewportWidthMedium: 750,
  viewportWidthLarge: 1112,
  columnPaddingNormal: 16,

  rowLayoutGapSmall: 16,
  rowLayoutGapNormal: 32,
  rowLayoutGapMedium: 48,
  rowLayoutGapLarge: 64,
  rowLayoutGapXLarge: 96
}

export const breakpoint = 600;
export const pageMargin = 5.55555;

export const pageMargins = css`
  padding: 0 ${spacing.padding.medium}px;
  @media (min-width: ${breakpoint * 1}px) {
    margin: 0 ${pageMargin * 1}%;
  }
  @media (min-width: ${breakpoint * 2}px) {
    margin: 0 ${pageMargin * 2}%;
  }
  @media (min-width: ${breakpoint * 3}px) {
    margin: 0 ${pageMargin * 3}%;
  }
  @media (min-width: ${breakpoint * 4}px) {
    margin: 0 ${pageMargin * 4}%;
  }
`;
