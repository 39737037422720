module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/circleci/project/src/components/layout/PageLayout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Splyt","short_name":"Splyt","start_url":"/","background_color":"#FFFFFF","theme_color":"#1591F9","display":"minimal-ui","icon":"/home/circleci/project/src/assets/splyt-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a058ac49b360760dd3879ae96a9b6a00"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-172630880-1"},
    }]
