import React from "react"
import styled from "styled-components"
import Helmet from "react-helmet"

import useSiteMetadata from "../hooks/useSiteMetadata"
import SiteHeader from "./SiteHeader"
import SiteMetadata from "../site-metadata"

export default function PageLayout({ location, children }) {
  return (
    <>
      <SiteMetadata pathname={location.pathname}/>
      {children}
    </>
  )
}