import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { color, spacing, typography } from "./shared/styles"

const Wrapper = styled(Link)`
  color: #FFFFFF;
  font-weight: ${typography.weight.medium};
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  display: block;
  padding: 10px 24px;
  
  background: ${color.primary};
  border-radius: ${spacing.borderRadius.default}px;
  
  &:hover, &:active {
    color: #FFFFFF;
    background: #216ED5;
  }
`;

export default function Button({ children, ...props }) {
  return (
    <Wrapper {...props}>
      {children}
    </Wrapper>
  )
}