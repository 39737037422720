import React, { useEffect, useRef, useState } from "react"
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { breakpoint, color, layout, pageMargins, typography } from "../shared/styles"

import LogoSVG from '../../assets/logo.svg';
import NavButtonSVG from '../../assets/nav-button.svg';
import Button from "../Button"

const Logo = styled.h1`

`;

const LogoLink = styled(Link)`
  display: inline-block;
  svg {
    height: 22px;
    width: auto;
    margin-top: 14px;
    @media (min-width: ${breakpoint}px) {
      height: 26px;
      margin-top: 10px;
    }
    display: block;
    transition: all 150ms ease-out;
    transform: translate3d(0, 0, 0);
    &:hover {
      transform: translate3d(0, -1px, 0);
    }
    &:active {
      transform: translate3d(0, 0, 0);
    }
  }
`;

const NavLink = styled(Link)`
  color: ${color.textSecondary};
  font-size: ${typography.size.p2}px;
  padding: 4px 16px;
  
  &:hover {
    color: ${color.textPrimary};
  }
  
  &.active {
    font-weight: ${typography.weight.medium};
    color: ${color.textPrimary};
  }
`;

const ListItem = styled.li`
  display: inline-block;
  vertical-align: top;
  ${(props) =>
    props.showDesktop &&
    css`
      display: none;
      @media (min-width: ${breakpoint * 1.333}px) {
        display: inline-block;
      }
    `};
  ${(props) =>
    props.showMobile &&
    css`
      @media (min-width: ${breakpoint * 1.333}px) {
        display: none;
      }
    `};
`;

const List = styled.ul`
  display: none;
  @media (min-width: ${layout.viewportWidthMedium}px) {
    display: flex;
  }

  align-items: center;
`;

const MenuNav = styled.nav`
  display: flex;
  @media (min-width: ${layout.viewportWidthMedium}px) {
    display: none;
  }
`;

const Nav = styled.nav`
  ${ListItem} + ${ListItem} {
    margin-left: 24px;
  }
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
  
  padding: 0 ${layout.columnPaddingNormal}px;
`;

const Container = styled.div`
  position: relative;
  max-width: calc(${layout.columnPaddingNormal * 2}px + 100vw - ${layout.gutterWidth * 2}px);
  margin: 0 auto;
  padding: 0 ${layout.columnPaddingNormal}px;
  
  @media (min-width: ${layout.viewportWidthLarge}px) {
    max-width: ${layout.columnPaddingNormal * 2 + layout.maxWidth}px;
  }
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  background: none;
  z-index: 100;
  
  width: 100%;
  
  transition: all 150ms ease-out;
  
  ${(props) => props.isSticky &&
  css`
    background: #FFFFFF;
    box-shadow: 0px 1px 20px rgba(23, 41, 75, 0.07);
  `}
`;

export default function SiteHeader({ ...props }) {
  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);
  const handleScroll = () => {
    const offset = window.scrollY;
    setSticky(offset > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    setSticky(window.scrollY > 0);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Header isSticky={isSticky} ref={ref}>
      <Container>
        <NavContainer>
          <Logo>
            <LogoLink href="/">
              <LogoSVG />
            </LogoLink>
          </Logo>
    {/*
          <Nav>
            <List>
              <ListItem>
                <NavLink to="/how-it-works" activeClassName="active">How it works</NavLink>
              </ListItem>
              <ListItem>
                <NavLink to="/card" activeClassName="active">Card</NavLink>
              </ListItem>
              <ListItem>
                <NavLink to="/faq" activeClassName="active">FAQ</NavLink>
              </ListItem>
              <ListItem>
                <NavLink to="/help" activeClassName="active">Help</NavLink>
              </ListItem>
              <ListItem>
                <Button href="https://account.splyt.app/">Sign In</Button>
              </ListItem>
            </List>
          </Nav>
                */}
          <MenuNav>
            <NavButtonSVG/>
          </MenuNav>
        </NavContainer>
      </Container>
    </Header>
  );
}
